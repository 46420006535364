<template>
    <div class="relative" ref="container">
        <canvas class="block w-full h-full" ref="canvas"> </canvas>
        <div id="child" class="absolute top-0 left-0 w-full h-full pointer-events-none">
            <slot class="pointer-events-auto"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Engine, Scene } from "@babylonjs/core";
import { onMounted, onUnmounted, ref } from "vue";

const container = ref();
const canvas = ref();

const emits = defineEmits<{
    (e: "onInit", scene: Scene, engine: Engine): void;
}>();

const props = defineProps({
    pauseRender: {
        type: Boolean,
        default: false,
    },
});

onMounted(() => {
    const engine = new Engine(canvas.value, true);
    const scene = new Scene(engine);
    scene.createDefaultCamera();
    emits("onInit", scene, engine);

    engine.runRenderLoop(() => {
        if (props.pauseRender) {
            engine.beginFrame();
            engine.endFrame();
        } else {
            engine.resize();
            scene.render();
        }
    });
    onUnmounted(() => {
        scene.dispose();
        engine.dispose();
    });
});
</script>
