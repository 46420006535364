import { Behavior, TransformNode } from "@babylonjs/core";

export default class AutoRotate implements Behavior<TransformNode> {
    name: string = "AutoRotate";
    observers = [] as any[];
    init(): void {}
    attach(target: TransformNode): void {
        let scene = target.getScene();

        target.reIntegrateRotationIntoRotationQuaternion = true;
        this.observers.push(
            scene.onBeforeRenderObservable.add((e) => {
                let delta = (scene.deltaTime ?? 16) / 1000;
                target.rotation.y += 1 * delta;
            }),
        );
    }
    detach(): void {
        this.observers.forEach((o) => o.remove());
        this.observers = [];
    }
}
