import { createApp } from "vue";
import "@unocss/reset/tailwind.css";

import "./assets/style.css";

import "virtual:uno.css";

import App from "./App.vue";

createApp(App).mount("#app");
