import { MeshBuilder, Scene, Vector3 } from "@babylonjs/core"

export class Debug {
    static DrawPoint(name: string, point: Vector3, scene: Scene, duration: number = 64) {
        let debugPoint = scene.getMeshByName("__DebugPoint__" + name)
        if (!debugPoint) {
            debugPoint = MeshBuilder.CreateSphere("__DebugPoint__" + name, { diameter: 0.08 }, scene)
            debugPoint.renderingGroupId = 1
            debugPoint.renderOverlay = true
            debugPoint.metadata ??= {}
        }
        debugPoint.position.copyFrom(point)
        debugPoint.position = debugPoint.position
        if (debugPoint.metadata.timer) {
            window.clearTimeout(debugPoint.metadata.timer)
        }
        debugPoint.metadata.timer = window.setTimeout(() => {
            debugPoint.dispose()
        }, duration)
    }
}
