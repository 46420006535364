import { ISceneComponent, Scene, TransformNode } from "@babylonjs/core";
import { extractXmlAttributes } from "../extractXmlAttributes";
import { BehaviorDB } from "../behaviorDB";

/**
 * 根据新加入场景模型的**名字**自动注册相应的行为脚本. 解析方式为xml
 */
export class AutoBehaviorComponent implements ISceneComponent {
    public name = "AutoBehaviorComponent";

    constructor(public scene: Scene) {
        if (scene._getComponent(this.name)) {
            return;
        }
        scene._addComponent(this);
    }

    // 注册行为脚本
    register(): void {
        const processNode = (node: TransformNode) => {
            this.scene.onBeforeRenderObservable.addOnce(() => {
                const extract = extractXmlAttributes(node.name); // 根据模型名字解析
                const cls = BehaviorDB.getBehaviorByName(extract?.tagName ?? "");

                if (!extract) {
                    return;
                }
                node.metadata = node.metadata || {};
                node.metadata = {
                    ...node.metadata,
                    ...extract.attributes,
                };

                node.metadata["class_name"] = extract.tagName;

                if (!cls) {
                    return;
                }
                const behavior = new cls();
                node.addBehavior(behavior);
            });
        };

        this.scene.onNewMeshAddedObservable.add(processNode);
        this.scene.onNewTransformNodeAddedObservable.add(processNode);
    }

    // 注销行为脚本
    unregister(): void {}

    // 更新
    update(): void {}
    // 重建
    rebuild(): void {}
    // 销毁
    dispose(): void {}
}


