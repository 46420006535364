import { OimoJSPlugin, TransformNode } from "@babylonjs/core";
import { extractXmlAttributes } from "./extractXmlAttributes";

export class BehaviorDB {
    private static _db: Record<string, any> = {};
    public static getBehaviorByName(name: string) {
        return this._db[name];
    }
    static RegiserClass(name: string, cls: any) {
        this._db[name] = cls;
    }

    static RegisterFromGlob(behaviors: Record<string, any>) {
        console.group("📝 Register Behavior");
        for (let name in behaviors) {
            let cls = behaviors[name];
            let className = name.substring(name.lastIndexOf("/") + 1, name.lastIndexOf("."));
            console.log({ className, cls });
            //@ts-ignore
            BehaviorDB.RegiserClass(className, cls as any);
        }
        console.groupEnd();
    }
}

BehaviorDB.RegisterFromGlob(import.meta.glob("./autoBehaviors/*.ts", { eager: true, import: "default" }));
