import { Behavior } from "@babylonjs/core/Behaviors/behavior"
import { PhysicsRaycastResult } from "@babylonjs/core/Physics/physicsRaycastResult"
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera"
import { Scalar, TmpVectors, Vector3 } from "@babylonjs/core"

export class PhyArcCameraCollision implements Behavior<ArcRotateCamera> {
    name: string = "BehaviorArcCameraCollision"

    private _originalGetViewMatrix: any
    target: ArcRotateCamera | undefined
    collideWith: number = 0b0001

    _targetRadius: number = 0
    _currentRadius: number = 0

    testSize: number = 1

    smooth: boolean = false
    smoothFactor: number = 20

    init(): void {}

    attach(target: ArcRotateCamera): void {
        const scene = target.getScene()
        if (!target.getScene().physicsEnabled) {
            console.warn("Physics engine not enabled, phyArcCameraCollision is invalid")
        }
        this.target = target
        this._originalGetViewMatrix = target._getViewMatrix.bind(target)

        let result = new PhysicsRaycastResult()
        target._getViewMatrix = () => {
            let originRaidus = target.radius
            let phyEngine = target.getScene().getPhysicsEngine()?.getPhysicsPlugin()!
            if (!phyEngine) {
                return this._originalGetViewMatrix()
            }

            let from = target.getTarget()

            let dir = TmpVectors.Vector3[1]
            target.getDirectionToRef(Vector3.LeftHandedBackwardReadOnly, dir)

            let to = from.add(dir.scale(originRaidus + this.testSize))

            phyEngine.raycast(from, to, result, { collideWith: this.collideWith })

            if (result.hasHit) {
                this._targetRadius = result.hitDistance - this.testSize
            } else {
                this._targetRadius = originRaidus
            }

            if (this.smooth) {
                this._currentRadius = Scalar.Lerp(
                    this._currentRadius,
                    this._targetRadius,
                    (this.smoothFactor * scene.getEngine().getDeltaTime()) / 1000,
                )
            } else {
                this._currentRadius = this._targetRadius
            }
            this._currentRadius = Math.max(0.001, this._currentRadius)

            target.radius = Math.min(originRaidus, this._currentRadius)

            let viewMatrix = this._originalGetViewMatrix(target)

            target.radius = originRaidus
            return viewMatrix
        }

        scene.onBeforeRenderObservable.add(() => {})
    }

    detach(): void {
        if (this.target) {
            this.target._getViewMatrix = this._originalGetViewMatrix
        }
    }
}
