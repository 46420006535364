import {
    Camera,
    HavokPlugin,
    Matrix,
    Mesh,
    MeshBuilder,
    PBRMaterial,
    PhysicsBody,
    PhysicsMaterialCombineMode,
    PhysicsMotionType,
    PhysicsShapeCapsule,
    Plane,
    Quaternion,
    Scalar,
    Scene,
    ShapeCastResult,
    TmpVectors,
    Vector2,
    Vector3,
} from "@babylonjs/core"
import { enableShadow } from "../function/enableShaodw"
import { Debug } from "../sceneComponent/debug"

export class PhyCharacter extends Mesh {
    // #region 自动旋转
    private _lockRotateEnable: boolean = true
    private _isLockToCamera: boolean = false

    public lockRotateSmooth = 20
    public lockRotateSmoothEnable = true

    public lockRotateToCamera() {
        this._isLockToCamera = true

        this._lockRotateEnable = true
    }

    public lockRotateToMovementDirection() {
        this._isLockToCamera = false

        this._lockRotateEnable = true
    }

    public disableLockRotate() {
        this._lockRotateEnable = false
    }

    private _processLockRoate() {
        if (!this._lockRotateEnable) {
            return
        }

        this.rotationQuaternion ??= this.rotation.toQuaternion()

        const m = TmpVectors.Matrix[0]
        this.rotationQuaternion.toRotationMatrix(m)

        if (this._isLockToCamera) {
            if (!this.getScene().activeCamera) return
            Matrix.RotationYawPitchRollToRef(this.getScene().activeCamera!.absoluteRotation.toEulerAngles().y, 0, 0, m)
        } else {
            if (this._inputVectorWorld.lengthSquared() < 0.01) return
            Matrix.LookDirectionLHToRef(this._inputVectorWorld.negate(), Vector3.UpReadOnly, m)
        }

        let amount = 1
        if (this.lockRotateSmoothEnable) {
            let delta = this.getScene().getEngine().getDeltaTime() / 1000
            amount = delta * this.lockRotateSmooth
        }
        let targetQuaternion = TmpVectors.Quaternion[0]
        targetQuaternion.fromRotationMatrix(m)

        Quaternion.SlerpToRef(this.rotationQuaternion, targetQuaternion, amount, this.rotationQuaternion)
    }
    // #endregion

    private _needUpdateShape = false
    debugMesh: Mesh | undefined

    private readonly angularFactor = 50

    /** 胶囊体高度 */
    public get height(): number {
        return this._height
    }
    public set height(value: number) {
        if (this.height === value) {
            return
        }
        this._needUpdateShape = true
        this._height = value
    }
    private _height: number = 1.8

    /** 胶囊体半径 */
    public get radius(): number {
        return this._radius
    }
    public set radius(value: number) {
        if (this.radius === value) return
        this._needUpdateShape = true
        this._radius = value
    }
    private _radius: number = 0.3

    // #region 跳跃 跳跃相关的属性和函数
    public maxJumpCount = 1 // 最大跳跃次数
    private currentJumpCount = 0 // 当前跳跃次数

    public jumpUpSpeed = 4 // 跳跃上升速度

    set maxJumpHeight(value: number) {
        this.jumpUpSpeed = Math.sqrt(2 * value * 9.8)
    }
    get maxJumpHeight() {
        return (this.jumpUpSpeed * this.jumpUpSpeed) / 2 / 9.8
    }

    public coyoteTime = 200 // 土狼时间

    private _isJumpFrame = false // 跳跃帧
    private _lastJumpTime = 0

    public jumpTol = 150 // 跳跃容错时间
    private _jumpTolTimer = -1 // 跳跃输入容错定时器

    // 标记跳跃帧
    jump() {
        this._isJumpFrame = true

        window.clearTimeout(this._jumpTolTimer)
        this._jumpTolTimer = window.setTimeout(() => {
            this._isJumpFrame = false
        }, this.jumpTol)
    }

    _canJump() {
        return (
            (this.currentJumpCount > 0 || (this.isFloor() && !this.isSlopSlide()) || this.isCoyoteJumpTime()) &&
            this.maxJumpCount > 0
        )
    }

    // 判断是否在土狼时间
    isCoyoteJumpTime() {
        return (
            Date.now() - this._lastFloorTime < this.coyoteTime &&
            Date.now() - this._lastJumpTime > this.coyoteTime &&
            !this.isSlopSlide()
        )
    }

    isSlopSlide() {
        return this.isFloor() && !this._canSlopeWalk()
    }
    // #endregion

    // #region 移动
    public walkMaxSpeed = 5 //最大速度
    public walkAcc = 50 // 到达目标速度前的加速度
    public walkDragAcc = 20 // 没有输入的情况下速度归零的加速度

    public airMaxSpeed = 5 //空中最大速度
    public airAcc = 10 // 到达最大速度前的空中加速度
    public airDragAcc = 5 // 没有输入的情况下速度归零的加速度

    public maxWalkSlopeAngle = 25 // 允许爬坡的最大角度
    _canSlopeWalk() {
        return (Math.acos(this._floorResult.hitNormal.dot(Vector3.UpReadOnly)) / Math.PI) * 180 < this.maxWalkSlopeAngle
    }
    // #endregion

    constructor(scene: Scene) {
        super("PhysicsCharacter", scene)
        this._updatePhysicsShape()
        scene.onBeforePhysicsObservable.add(this.update.bind(this))
    }

    _inputVectorWorld = Vector3.Zero() // 一帧中累计的输入
    public addInputAxis(inputAxis: Vector2) {
        let temp = Vector3.Zero()
        inputVectorToWorldRef(inputAxis, this.getScene().activeCamera!, temp)
        this.addInputWorldAxis(temp)
    }

    public addInputWorldAxis(worldDir: Vector3) {
        this._inputVectorWorld.addInPlace(worldDir)
    }

    // #tag update 主要进行计算的地方
    update() {
        if (this._needUpdateShape) {
            this._updatePhysicsShape()
        }
        if (!this.physicsBody) return
        this._updateFloor()

        let isFloor = this.isFloor()

        this._inputVectorWorld.normalize() // 归一化输入

        this._processLockRoate()

        let velocity = TmpVectors.Vector3[0]

        this.physicsBody?.getLinearVelocityToRef(velocity)

        // #region 本地速度计算
        velocity.subtractInPlace(this.getFloorVelocity()) // 速度切换到 Floor空间

        let acc = isFloor ? this.walkAcc : this.airAcc
        let dragAcc = isFloor ? this.walkDragAcc : this.airDragAcc
        acc = this._inputVectorWorld.lengthSquared() > 0.01 ? acc : dragAcc

        let maxSpeed = isFloor ? this.walkMaxSpeed : this.airMaxSpeed
        let delteTime = this.getScene().getEngine().getDeltaTime() / 1000

        let targetVelocity = TmpVectors.Vector3[1]
        targetVelocity.copyFrom(this._inputVectorWorld)
        targetVelocity.scaleInPlace(maxSpeed)

        if (isFloor && !this._canSlopeWalk()) {
            acc *= 0.1
        }

        velocity.x = Scalar.MoveTowards(velocity.x, targetVelocity.x, acc * delteTime)
        velocity.z = Scalar.MoveTowards(velocity.z, targetVelocity.z, acc * delteTime)

        if (isFloor) {
            // 爬坡判断
            if (this._canSlopeWalk()) {
                this.currentJumpCount = this.maxJumpCount

                let temp = TmpVectors.Vector3[2]
                temp.copyFrom(velocity)
                temp.y = 0
                let sin = Plane.SignedDistanceToPlaneFromPositionAndNormal(
                    Vector3.Zero(),
                    this._floorResult.hitNormal,
                    temp.normalizeToNew(),
                )
                let hSpeed = temp.length()
                let vSpeed = hSpeed * Math.tan(sin) * 1.01 // 别问,问就是勾股定理
                velocity.y = -vSpeed
            } else {
                let normal = this._floorResult.hitNormal.clone()
                normal.y = 0
                normal.normalize()
                // 下滑力
                velocity.x += normal.x * delteTime * 1
                velocity.z += normal.z * delteTime * 1
                velocity.y = Math.min(velocity.y, velocity.y * 0.8) // 防止莽夫
                if (velocity.y < 1) {
                    velocity.y += -9.8 * 1 * delteTime
                }
                this.currentJumpCount = Math.min(this.currentJumpCount, this.maxJumpCount - 1)
            }
        }

        // 离开地面且不在土狼时间时，减少跳跃次数
        if (!this.isFloor() && !this.isCoyoteJumpTime()) {
            this.currentJumpCount = Math.min(this.currentJumpCount, this.maxJumpCount - 1)
        }

        // jump
        if (this._isJumpFrame && this._canJump()) {
            // 地面跳跃
            if (this.isFloor() || this.isCoyoteJumpTime()) {
                this.currentJumpCount = this.maxJumpCount
            }

            velocity.y = this.jumpUpSpeed

            this.currentJumpCount -= 1
            this._lastJumpTime = Date.now()
            this._isJumpFrame = false
        }

        velocity.addInPlace(this.getFloorVelocity()) // 速度返回世界空间
        if (this.angularFactor > 0) {
            velocity.addInPlace(this.calcAngularToLinearVelocity().scale(delteTime * this.angularFactor))
        }

        // #endregion

        // applay
        this.physicsBody?.setLinearVelocity(velocity)

        // clear
        this._inputVectorWorld.setAll(0)
    }

    _floorResult = new ShapeCastResult()
    _floorShapeResult = new ShapeCastResult() // 暂时未使用, 只是因为物理引擎需要提供这个
    _lastFloorTime = 0
    _updateFloor() {
        const hk = this.getScene().getPhysicsEngine()?.getPhysicsPlugin()

        if (hk instanceof HavokPlugin) {
            this._floorResult.reset()

            let startPosition = TmpVectors.Vector3[0]
            let endPosition = TmpVectors.Vector3[1]
            startPosition.copyFrom(this.absolutePosition)
            endPosition.copyFrom(startPosition)
            endPosition.y -= this.height * 0.01 // 容错
            // #tag 地面检测
            hk.shapeCast(
                {
                    shape: this.physicsBody!.shape!,
                    startPosition: startPosition,
                    endPosition: endPosition,
                    rotation: this.absoluteRotationQuaternion,
                    shouldHitTriggers: false,
                },
                this._floorShapeResult,
                this._floorResult,
            )

            if (!this.isFloor()) {
                let lastBody = this._floorResult.body
                hk.shapeCast(
                    {
                        shape: this.physicsBody!.shape!,
                        startPosition: startPosition,
                        endPosition: endPosition,
                        rotation: this.absoluteRotationQuaternion,
                        shouldHitTriggers: false,
                        ignoreBody: lastBody,
                    },
                    this._floorShapeResult,
                    this._floorResult,
                )
            }

            if (this._floorResult.hasHit) {
                this._lastFloorTime = Date.now()
                this._floorResult.body?.getLinearVelocityToRef(this._floorVelocity)
                this._floorResult.body?.getAngularVelocityToRef(this._floorAngularVelocity)
            } else {
                this._floorVelocity.setAll(0)
                this._floorAngularVelocity.setAll(0)
            }
            // debug floor
            let point = this._floorResult.hitPoint
            let name = "floor point"
            if (this.isFloor()) {
                Debug.DrawPoint(name, point, this.getScene(), 32)
            }
        }
    }

    _floorVelocity = Vector3.Zero()
    getFloorVelocity() {
        return this._floorVelocity
    }

    _floorAngularVelocity = Vector3.Zero()
    getFloorAngularVelocity() {
        return this._floorAngularVelocity
    }

    _floorRotVelocity = Vector3.Zero()
    _floorLocalPosition = Vector3.Zero()
    calcAngularToLinearVelocity() {
        if (!this._floorResult.hasHit) {
            this._floorRotVelocity.setAll(0)
            return this._floorRotVelocity
        }

        this.absolutePosition.subtractToRef(
            this._floorResult.body?.transformNode.absolutePosition!,
            this._floorLocalPosition,
        )
        this._floorLocalPosition.y = 0
        this._floorLocalPosition.normalize()
        let m = TmpVectors.Matrix[0]

        Matrix.RotationYToRef(this._floorAngularVelocity.y, m)

        let velocity = Vector3.TransformNormal(this._floorLocalPosition, m).subtract(this._floorLocalPosition)
        return velocity
    }

    /** 是否在地面 */
    isFloor() {
        let distance = TmpVectors.Vector2[0]
            .set(this._floorShapeResult.hitPoint.x, this._floorShapeResult.hitPoint.z)
            .length()

        return this._floorResult.hasHit && distance < this.radius * 0.8
    }

    /**
     * 生成新的胶囊体
     */
    _updatePhysicsShape() {
        this._needUpdateShape = false
        if (!this.physicsBody) {
            this.physicsBody = new PhysicsBody(this, PhysicsMotionType.DYNAMIC, false, this.getScene())
            this.physicsBody!.setMassProperties({ inertia: Vector3.Zero() })
            this.physicsBody.disablePreStep = false
        }

        // radius
        // ┌───┐
        // │ x─┼── pointA
        // │ x─┼── pointB
        // └───┘
        const pointA = new Vector3(0, this._height - this._radius, 0)
        const pointB = new Vector3(0, this._radius, 0)
        let shape = new PhysicsShapeCapsule(pointA, pointB, this._radius, this.getScene())
        shape.material = {
            friction: 0,
            restitution: 0,
            restitutionCombine: PhysicsMaterialCombineMode.MULTIPLY,
        }
        shape.filterCollideMask = 0b0011
        shape.filterMembershipMask = 0b0010
        this.physicsBody.shape = shape

        // debug shape
        this.debugMesh?.dispose()
        this.debugMesh = MeshBuilder.CreateCapsule("debug", { height: this.height, radius: this._radius })
        this.debugMesh.position.y = this.height / 2
        const mat = new PBRMaterial("debug player", this.getScene())
        mat.metallic = 0
        mat.roughness = 1
        this.debugMesh.material = mat
        this.debugMesh.parent = this
        enableShadow(this.debugMesh)
    }
}

export function inputVectorToWorldRef(inputVector: Vector2, camera: Camera, result: Vector3) {
    result.x = inputVector.x
    result.y = 0
    result.z = inputVector.y
    let m = Matrix.RotationY(camera.absoluteRotation.toEulerAngles().y)
    Vector3.TransformCoordinatesToRef(result, m, result)
    return result
}
