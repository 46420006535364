import {
    AbstractMesh,
    HavokPlugin,
    ISceneComponent,
    Observable,
    PhysicsBody,
    PhysicsEventType,
    Scene,
} from "@babylonjs/core"
import { generateCollision } from "../function/generateCollision"
import { Component } from "../nodes/nodeComponent"

export const TriggerSymbol = Symbol("Trigger")

/**
 * 触发器,
 */
export default class Trigger extends Component<AbstractMesh> {
    name: string = "Trigger"

    onBodyEnteredObservable = new Observable<PhysicsBody>()

    onBodyLeftObservable = new Observable<PhysicsBody>()

    constructor() {
        super()
    }

    attach(target: AbstractMesh): void {
        super.attach(target)
        TriggerSceneComponent.Instance(target.getScene())

        target.metadata[TriggerSymbol] = this
        generateCollision(target, {
            box: 1,
            ...target.metadata,
        })
        let body = target.physicsBody!
        body.shape!.isTrigger = true
    }

    _onBodyEntered(body: PhysicsBody) {
        this.onBodyEnteredObservable.notifyObservers(body)
    }
    _onBodyLeft(body: PhysicsBody) {
        this.onBodyLeftObservable.notifyObservers(body)
    }

    dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean): void {
        super.dispose(doNotRecurse, disposeMaterialAndTextures)
    }
}

export class TriggerSceneComponent implements ISceneComponent {
    name: string = "triggerSceneComponent"
    constructor(public scene: Scene) {
        if (scene._getComponent(this.name)) {
            return
        }
        scene._addComponent(this)
    }

    register(): void {
        this.scene.onAfterPhysicsObservable.addOnce((e) => {
            let hk = this.scene.getPhysicsEngine()?.getPhysicsPlugin() as HavokPlugin
            hk.onTriggerCollisionObservable.add((e) => {
                let trigger = e.collidedAgainst.transformNode.getBehaviorByName("Trigger") as Trigger
                if (e.type == PhysicsEventType.TRIGGER_ENTERED) {
                    trigger?._onBodyEntered(e.collider)
                } else if (e.type == PhysicsEventType.TRIGGER_EXITED) {
                    trigger?._onBodyLeft(e.collider)
                }
            })
        })
    }
    rebuild(): void {}
    dispose(): void {}

    static Instance(scene: Scene) {
        if (scene._getComponent("triggerSceneComponent")) {
            return scene._getComponent("triggerSceneComponent") as TriggerSceneComponent
        }
        return new TriggerSceneComponent(scene)
    }
}
