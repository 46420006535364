import { IInspectorOptions, KeyboardEventTypes, Node, Scene } from "@babylonjs/core"

/**
 * 为Scene添加调试面板快捷键
 */
export class DebugLayerShortcut extends Node {
    _config: IInspectorOptions = {}
    _InspectorPromise: any

    constructor(scene: Scene, config?: IInspectorOptions) {
        super("DebugKeyboard", scene)
        this._config = { ...config }

        let keyboardObserver = scene.onKeyboardObservable.add((kbInfo) => {
            if (!(kbInfo.type == KeyboardEventTypes.KEYDOWN && kbInfo.event.key.toLocaleLowerCase() == "i")) {
                return
            }

            if (import.meta.env.DEV) {
                const toggleInspector = () => {
                    if (scene.debugLayer.isVisible()) {
                        scene.debugLayer.hide()
                    } else {
                        scene.debugLayer.show({ embedMode: true, ...this._config })
                    }
                }

                if (!this._InspectorPromise) {
                    this._InspectorPromise = import("@babylonjs/inspector")
                }
                this._InspectorPromise.then(toggleInspector)
            }
        })

        this.onDisposeObservable.add(() => {
            keyboardObserver.remove()
        })
    }
}

