import {
    PhysicsShapeType,
    PhysicsAggregate,
    AbstractMesh,
    PhysicsMotionType,
    PhysicsPrestepType,
} from "@babylonjs/core"

type Params = {
    static?: number
    phy?: number
    box?: number
    sphere?: number
    complex?: number
}

/**
 * 为目标网格生成一种类型的碰撞
 */
export function generateCollision(target: AbstractMesh, param: Params) {
    if (!target.getScene().physicsEnabled) {
        console.error("物理引擎未开启")
        return
    }
    param = {
        static: 1,
        phy: 0,
        ...param,
    }

    let type = PhysicsShapeType.CONVEX_HULL
    if (param.box) {
        type = PhysicsShapeType.BOX
    }
    if (param.sphere) {
        type = PhysicsShapeType.SPHERE
    }
    if (param.complex) {
        type = PhysicsShapeType.MESH
    }

    let aggregate = new PhysicsAggregate(target, type, {
        mass: param.phy == 1 ? 1 : 0, // mass 为0则默认为静态, 为1则为动态物体
    })
    aggregate.body.setMassProperties({}) // 为了自动计算质量

    aggregate.body.shape!.filterCollideMask = 0xffff_ffff
    aggregate.body.shape!.filterMembershipMask = 0b0000_0001

    if(param.phy == 1) {
        aggregate.body.setMotionType(PhysicsMotionType.DYNAMIC)
        aggregate.body.shape!.filterMembershipMask = 0b0000_0010
    }
    if (param.static == 0) {
        aggregate.body.setMotionType(PhysicsMotionType.ANIMATED)
        aggregate.body.setPrestepType(PhysicsPrestepType.ACTION)
        aggregate.body.disableSync = true
        aggregate.body.shape!.filterMembershipMask = 0b0000_0010
    }


}
