import { IKeyboardEvent, ISceneComponent, KeyboardInfo, Observer, Scene, Vector2 } from "@babylonjs/core"

/**
 * 定义输入动作和触发动作所需要的按键code
 * @example
 *      export const moveLeft = new Action("moveLeft").addKey({ code: "KeyA" });
 *      export const moveUp = new Action("moveUp").addKey({ code: "KeyW" });
 */
export class Action {
    name: string = ""
    keys: Key[] = []

    constructor(name: string) {
        this.name = name
    }

    addKey(key: Key) {
        this.keys.push(key)
        return this
    }

    checkEvent(event: IKeyboardEvent) {
        return !!this.keys.find((e) => {
            if (e.ctrl && event.ctrlKey != !!e.ctrl) return false
            if (e.alt && event.altKey != !!e.alt) return false
            if (e.shift && event.shiftKey != !!e.shift) return false
            return event.code == e.code
        })
    }
}

interface Key {
    code: string
    //TODO -
    ctrl?: boolean
    alt?: boolean
    shift?: boolean
}

class ActionState {
    value = 0
    _lastValue = 0

    justPressed = false
    justReleased = false
    isPressed = false
}

/**
 * 输入事件系统场景组件, 不需要手动注册到场景
 * @example
 *   InputActionComponent.RegisterAction(moveLeftAction)
 *   InputActionComponent.Instance(scene).getActionValue(moveLeftAction.name)
 */
export class InputActionComponent implements ISceneComponent {
    name: string = "InputActionComponent"

    static ActionMap = new Map<string, Action>()

    actionStateMap = new Map<string, ActionState>()

    private _keyboardObserver: Observer<any> | undefined
    private _updateObserver: Observer<any> | undefined

    constructor(public scene: Scene) {
        if (scene._getComponent("InputAction")) {
            return
        }
        scene._addComponent(this)
    }

    /**
     * 获取动作的输入状态
     */
    getActionState(action: string | Action) {
        if (typeof action !== "string") {
            action = action.name
        }

        let state = this.actionStateMap.get(action)
        if (!state) {
            state = new ActionState()
            this.actionStateMap.set(action, state)
        }
        return state
    }

    /**
     * 获取动作的值
     */
    getActionValue(action: string | Action) {
        return this.getActionState(action).value
    }

    /**
     * 通过两个动作确定一个轴
     */
    getAxisValue(leftAction: string | Action, rightAction: string | Action) {
        const left = this.getActionValue(leftAction)
        const right = this.getActionValue(rightAction)
        return -left + right
    }

    /**
     * 通过四个动作返回一个Vector2
     */
    get2AxisValue(
        leftAction: string | Action,
        rightAction: string | Action,
        upAction: string | Action,
        downAction: string | Action,
    ) {
        const x = this.getAxisValue(leftAction, rightAction)
        const y = this.getAxisValue(downAction, upAction)
        let vec = new Vector2(x, y)
        return vec
    }

    register(): void {
        // keyboard
        this._keyboardObserver = this.scene.onKeyboardObservable.add((evt) => {
            InputActionComponent.ActionMap.forEach((action, name) => {
                const state = this.getActionState(action.name)
                if (action.checkEvent(evt.event)) {
                    evt.event.type === "keydown" ? (state.value = 1) : (state.value = 0)
                }
            })
        })
        this._updateObserver = this.scene.onAfterRenderObservable.add(this.update.bind(this), undefined, true)
    }

    rebuild(): void {}

    dispose(): void {
        this._keyboardObserver?.remove()
        this._updateObserver?.remove()
        this.actionStateMap.clear()
    }

    update() {
        this.actionStateMap.forEach((state, name) => {
            let lastPress = state._lastValue > 0.5
            let justPress = state.value > 0.5
  
            if (justPress === lastPress) {
                // 按住或者松开
                state.justPressed = false
                state.justReleased = false
            } else {
                if (state.value > 0.5) {
                    // 按下
                    state.justPressed = true
                    state.justReleased = false
                    state.isPressed = true
                }
                if (state._lastValue > 0.5) {
                    // 松开
                    state.justPressed = false
                    state.justReleased = true
                    state.isPressed = false
                }
            }
            state._lastValue = state.value
        })
    }

    static Instance(scene: Scene) {
        return (scene._getComponent("InputActionComponent") ?? new InputActionComponent(scene)) as InputActionComponent
    }

    static RegisterAction(...actions: Action[]) {
        actions.forEach((e) => {
            this.ActionMap.set(e.name, e)
        })
    }
}
