import {
    AbstractMesh,
    Behavior,
    InspectableType,
    Node,
    PhysicsAggregate,
    PhysicsBody,
    PhysicsMotionType,
} from "@babylonjs/core"
import { Component } from "../nodes/nodeComponent"
import { generateCollision } from "../function/generateCollision"

export default class Mesh extends Component<AbstractMesh> {
    name: string = "Mesh"

    attach(target: AbstractMesh): void {
        super.attach(target)

        const params = {
            static: 1,
            phy: 0,
            box: 0,
            sphere: 0,
            ...target.metadata,
        }

        generateCollision(target, params)
    }
}
