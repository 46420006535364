import { AbstractMesh, ShadowGenerator } from "@babylonjs/core"

export function enableShadow(mesh: AbstractMesh, receiveShadows: boolean = true) {
    let shadows = mesh.getScene().lights.map((e) => e.getShadowGenerator())
    mesh.receiveShadows = receiveShadows
    shadows.forEach((shadow) => {
        if (shadow instanceof ShadowGenerator) {
            shadow.addShadowCaster(mesh)
        }
    })
}
