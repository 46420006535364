import { AbstractMesh } from "@babylonjs/core"
import { generateCollision } from "../function/generateCollision"
import { Component } from "../nodes/nodeComponent"

export default class BoxCollision extends Component<AbstractMesh> {
    name: string = "BoxCollision"

    attach(target: AbstractMesh): void {
        super.attach(target)
        generateCollision(target, { box: 1, ...target.metadata })
        target.isVisible = false
    }
}
