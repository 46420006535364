import { AbstractMesh } from "@babylonjs/core"
import { generateCollision } from "../function/generateCollision"
import { Component } from "../nodes/nodeComponent"

export default class MeshCollision extends Component<AbstractMesh> {
    name: string = "MeshCollision"

    attach(target: AbstractMesh): void {
        super.attach(target)
        generateCollision(target, { complex: 1, ...target.metadata })
        target.isVisible = false
    }
}
