import { Scene } from "@babylonjs/core"

export function setTimeoutByScene(func: () => void, timeout: number, scene: Scene) {
    let handle = setTimeout(func, timeout)
    scene.onDisposeObservable.addOnce(() => {
        clearTimeout(handle)
    })
    return handle
}

export function setIntervalByScene(func: () => void, interval: number, scene: Scene) {
    let handle = setInterval(func, interval)
    scene.onDisposeObservable.addOnce(() => {
        clearInterval(handle)
    })
    return handle
}
