import { AssetContainer, InstantiatedEntries, ISceneComponent, Observable, Scene, SceneLoader } from "@babylonjs/core";
import { logProgress } from "../tools/logTools";

type LoadState = {
    progress: number;
    total: number;
    isloading: boolean;
    loadingPromise?: Promise<AssetContainer>;
};

export const ASSET_COMPONENT_NAME = "AssetsComponent";

/**
 * 统一管理资源加载进度和模型资源实例化
 * 具体来说, 使用modelAsset定义模型资源, 内部使用AssetContainer管理模型资源, 重复加载的模型会使用同一个AssetContainer实例化.
 * @example
 * ```ts
 *      let modelAsset = ModelAsset.Create(rootUrl, fileName)
 *      modelAsset.instantiate(scene)
 * ```
 */
export default class AssetsComponent implements ISceneComponent {
    name: string = ASSET_COMPONENT_NAME;

    _assetContainers = new Map<ModelAsset, AssetContainer>();
    state = new Map<ModelAsset, LoadState>();

    onProgressObservable = new Observable<AssetsComponent>();
    onLoadedObservable = new Observable<AssetsComponent>();

    get isLoading() {
        const temp = [...this.state.values()];
        return temp.findIndex((value) => value.isloading == true) !== -1;
    }

    get total() {
        const temp = [...this.state.values()];
        return temp.reduce((a, b) => a + b.total, 0);
    }

    get percent() {
        return this.progress / this.total;
    }

    get progress() {
        const temp = [...this.state.values()];
        return temp.reduce((a, b) => a + b.progress, 0);
    }

    constructor(public scene: Scene) {}

    register(): void {
        // nothing
    }

    rebuild(): void {
        // nothing
    }

    dispose(): void {
        this._assetContainers.clear();
        this.state.clear();
    }

    async instantiate(asset: ModelAsset) {
        let container = await this._loadOrGetAssetContainer(asset);
        if (!container) {
            console.warn("模型资源加载失败");
            return undefined;
        }
        let entries = container.instantiateModelsToScene((e) => e);
        return entries;
    }

    /**
     * 加载或获取AssetContainer
     * @internal
     */
    async _loadOrGetAssetContainer(asset: ModelAsset) {
        if (this._assetContainers.has(asset)) {
            return this._assetContainers.get(asset);
        }

        // 如果正在加载, 直接返回promise
        let loadingPromise = this.state.get(asset)?.loadingPromise;
        if (loadingPromise) {
            return await loadingPromise;
        }

        let containerPromise = SceneLoader.LoadAssetContainerAsync(
            asset.rootUrl,
            asset.fileName,
            this.scene,
            /*onProgress:*/ ({ lengthComputable, loaded, total }) => {
                if (lengthComputable) {
                    this.state.set(asset, {
                        progress: loaded,
                        total: total,
                        isloading: true,
                        loadingPromise: containerPromise,
                    });
                } else {
                    let dlCount = loaded / (1024 * 1024);
                    this.state.set(asset, {
                        progress: dlCount * 100,
                        total: 100,
                        isloading: true,
                        loadingPromise: containerPromise,
                    });
                }

                this.onProgressObservable.notifyObservers(this);
                logProgress(this.percent);
                if (this.percent >= 1) {
                    console.log("🎉 模型资源加载完成 🎉");
                    this.onLoadedObservable.runCoroutineAsync;
                }
            },
            asset.extension,
        );
        this.state.set(asset, { progress: 0, total: 1024*1024, isloading: true, loadingPromise: containerPromise });

        let container = await containerPromise;

        this.state.set(asset, { ...this.state.get(asset)!, isloading: false });
        this._assetContainers.set(asset, container);
        return container;
    }

    static GetOrCreateInstance(scene: Scene) {
        let instance = scene._getComponent(ASSET_COMPONENT_NAME) as AssetsComponent;
        if (!instance) {
            instance = new AssetsComponent(scene);
            scene._addComponent(instance);
        }
        return instance as AssetsComponent;
    }
}

/** 定义一个函数类型,此函数接受一个模型资源进行初始化设置, 返回一个销毁函数 */
type setupFunc = (instance: InstantiatedEntries) => () => void;

export class ModelAsset {
    rootUrl: string = "";
    fileName: string = "";
    extension: string | undefined;
    setup?: setupFunc;

    static DefineAsset(rootUrl: string, fileName: string, extension?: string, setup?: setupFunc) {
        let asset = new ModelAsset();
        asset.rootUrl = rootUrl;
        asset.fileName = fileName;

        return asset;
    }

    async preload(scene: Scene) {
        AssetsComponent.GetOrCreateInstance(scene)._loadOrGetAssetContainer(this);
    }

    // 实例化函数
    async instantiate(scene: Scene) {
        let entries = await AssetsComponent.GetOrCreateInstance(scene).instantiate(this);
        if (!entries) {
            throw new Error("模型资源加载失败, 无法实例化");
        }
        if (this.setup) {
            let dispose = this.setup(entries);
            let sourceDispose = entries.dispose;
            entries.dispose = () => {
                dispose();
                sourceDispose.bind(entries)();
            };
        }
        return entries;
    }

    getState(scene: Scene) {
        return AssetsComponent.GetOrCreateInstance(scene).state.get(this);
    }
}
