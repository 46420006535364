import { Action, InputActionComponent } from "../../addons/sceneComponent/inputActionComponent"

export const moveLeft = new Action("moveLeft").addKey({ code: "KeyA" })
export const moveUp = new Action("moveUp").addKey({ code: "KeyW" })

export const moveDown = new Action("moveDown").addKey({ code: "KeyS" })

export const moveRight = new Action("moveRight").addKey({ code: "KeyD" })

export const jump = new Action("jump").addKey({ code: "Space" })

export const skill = new Action("skill").addKey({ code: "KeyF" })

export const DefaultActions = {
    moveLeft,
    moveUp,
    moveDown,
    moveRight,
    jump,
    skill,
}

export function useDefaultActions() {
    InputActionComponent.RegisterAction(...Object.values(DefaultActions))
}
useDefaultActions()
