import { Behavior, ISceneComponent, Scene, TransformNode } from "@babylonjs/core"

/**
 * 即是节点也是组件
 */
export abstract class Component<T extends TransformNode> extends TransformNode implements Behavior<T> {
    abstract name: string

    params: Record<string, any> = {}

    constructor() {
        super("Component", undefined)
    }

    init(): void {}

    attach(target: T): void {
        target.getScene().addTransformNode(this)
        this.parent = target
    }

    detach(): void {
        this.dispose()
    }

    get target(): T {
        return this.parent as T
    }
}

/**
 * 通过类获取组件
 */
export function getComponentByClass<T extends ISceneComponent>(scene: Scene, cls: new (...params: any) => T) {
    return scene._components.find((c) => c instanceof cls) as T | undefined
}
