export function extractXmlAttributes(htmlTag: string) {
    // 用正则表达式提取标签的名字
    const tagNameMatch = htmlTag.match(/^<\s*([a-zA-Z][\w-]*)/);
    const tagName = tagNameMatch ? tagNameMatch[1] : null;

    // 确保只提取标签内部的属性部分
    const attributesPart = htmlTag.match(/<\s*[a-zA-Z][\w-]*\s*(.*?)\s*>/);
    const attributesString = attributesPart ? attributesPart[1] : "";

    // 用正则表达式提取所有的属性
    const attributesRegex = /(\w+)(?:\s*=\s*(?:"([^"]*)"|'([^']*)'|(\S+)))?/g;
    const attributes = {} as Record<string, string>;
    let match;

    while ((match = attributesRegex.exec(attributesString))) {
        const attrName = match[1];
        const attrValue = match[2] || match[3] || match[4] || "";

        attributes[attrName] = attrValue;
    }

    return {
        tagName,
        attributes,
    };
}
