<template>
    <BabylonScene class="w-full h-full" @on-init="onInit"> </BabylonScene>
</template>

<script setup lang="ts">
import BabylonScene from "./addons/components/BabylonScene.vue"
import {
    ArcRotateCamera,
    CascadedShadowGenerator,
    CubeTexture,
    Deferred,
    DirectionalLight,
    Scene,
    TransformNode,
    Vector3,
} from "@babylonjs/core"
import { ModelAsset } from "./addons/sceneComponent/assetsComponent"
import "@babylonjs/loaders/glTF/2.0"
import { AutoBehaviorComponent } from "./addons/sceneComponent/autoBehaviorComponent"
import { enableHavokPhysics as useHavokPhysics } from "./addons/function/useHavokPhysics"
import { PhyCharacter } from "./addons/nodes/phyCharacter"
import { DebugLayerShortcut } from "./addons/function/addDebuglayerShortcut"
import { InputActionComponent } from "./addons/sceneComponent/inputActionComponent"
import { jump, moveDown, moveLeft, moveRight, moveUp, skill, useDefaultActions } from "./script/actions/defaultActions"
import Trigger from "./addons/autoBehaviors/Trigger"
import { setIntervalByScene, setTimeoutByScene } from "./addons/function/timer"
import { enableShadow } from "./addons/function/enableShaodw"
import { PhyArcCameraCollision } from "./addons/behaviors/phyArcCameraCollision"

const characterModel = ModelAsset.DefineAsset("", "./models/character.glb")
const testModel = ModelAsset.DefineAsset("", "./models/tag-test.glb")
useDefaultActions()

async function onInit(scene: Scene) {
    // 异步依赖项
    const hk = await useHavokPhysics(scene)
    await Promise.all([testModel.preload(scene), characterModel.preload(scene)])

    new AutoBehaviorComponent(scene)
    new DebugLayerShortcut(scene)

    scene.environmentTexture = CubeTexture.CreateFromPrefilteredData("./env/cloudy.env", scene)
    scene.imageProcessingConfiguration.toneMappingEnabled = true
    scene.createDefaultLight()

    testModel.instantiate(scene)

    const onReady = new Deferred()
    scene.executeWhenReady(() => onReady.resolve(undefined))
    await onReady.promise

    scene.animationGroups.forEach((e) => {
        if (e.name.includes("<Stop>")) return
        e.play(true)
    })

    const character = new PhyCharacter(scene)
    character.height = 1

    let offsetY = new TransformNode("offsetY", scene)
    offsetY.parent = character
    offsetY.position.y = 1
    offsetY.billboardMode = 2

    let offsetX = new TransformNode("offsetX", scene)
    offsetX.parent = offsetY
    offsetX.position.x = 0.25

    let camera = createCamera(scene)
    camera.targetHost = offsetX
    camera.addBehavior(new PhyArcCameraCollision())


    let mark1 = scene.getMeshByName("<Mesh sphere=1 phy=1>球体.001")?.absolutePosition.clone()!
    let mark2 = scene.getMeshByName("<Mesh sphere=1 phy=1>球体.002")?.absolutePosition.clone()!

    let spawn1 = () => {
        let sphere = scene.getMeshByName("<Mesh sphere=1 phy=1>球体.001")?.clone("", null)
        if (!sphere) return
        sphere.physicsBody!.disablePreStep = false
        sphere.setAbsolutePosition(mark1)
        sphere.physicsBody?.setMassProperties({ mass: 10000000 })

        enableShadow(sphere)
        setTimeoutByScene(() => sphere.dispose(), 15000, scene)
    }
    setIntervalByScene(spawn1, 3000, scene)

    let sky = scene.getMeshByName("球体")
    if (sky) {
        sky.metadata = { noShadow: true, ...sky.metadata }
    }

    scene.freeActiveMeshes()
    scene.meshes.forEach((e) => {
        e.isPickable = false
    })

    const input = InputActionComponent.Instance(scene)
    scene.onBeforePhysicsObservable.add(() => {
        let inputAxis = input.get2AxisValue(moveLeft, moveRight, moveUp, moveDown)
        character.addInputAxis(inputAxis)

        if (input.getActionState(jump).justPressed) {
            character.jump()
        }

        if (input.getActionState(skill).justPressed) {
            console.log("skill")
            character.physicsBody?.applyImpulse(new Vector3(0, 10, 0), character.getAbsolutePosition())
        }
    })

    scene.environmentIntensity = 0.8
    let sun = createSunAndShadow(scene)

    let triggers = scene.transformNodes.filter((e) => e instanceof Trigger)

    triggers.forEach((trigger) => {
        if (trigger instanceof Trigger) {
            trigger.onBodyEnteredObservable.add((body) => {
                let start = scene.transformNodes.find((e) => e.name.startsWith("Playerstart")) as TransformNode
                body.transformNode.setAbsolutePosition(start.absolutePosition)
            })
        }
    })
}

function createCamera(scene: Scene) {
    let camera = new ArcRotateCamera("defaultCamera", 0, 1, 10, Vector3.Zero(), scene)
    camera.minZ = 0.1
    camera.maxZ = 5000
    camera.radius = 5
    camera.lowerRadiusLimit = 4
    camera.upperRadiusLimit = 16
    camera.wheelDeltaPercentage = 0.01
    scene.switchActiveCamera(camera)
    return camera
}

function createSunAndShadow(scene: Scene) {
    let light = new DirectionalLight("sun", new Vector3(-1, -1, -0.5), scene)
    light.intensity = 3
    let shadow = new CascadedShadowGenerator(1024, light)
    shadow.numCascades = 2
    shadow.shadowMaxZ = 100
    shadow.bias = 0.005
    shadow.normalBias = 0.005
    scene.meshes.forEach((e) => {
        if (e.metadata?.noShadow === true) return
        shadow.addShadowCaster(e, false)
        e.receiveShadows = true
    })
    return { light, shadow }
}
</script>
